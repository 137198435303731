.pagination-container {
  margin: 32px auto 8px auto;
  display: flex;
  list-style-type: none;
  align-items: center;
  justify-content: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  gap: 8px;
  svg {
    margin-top: 3px;
    transform: scale(1.15);
    path {
      fill: #2c6ef2;
    }
  }
  .pagination-item {
    padding: 0 12px;
    height: 32px;
    text-align: center;
    color: #2c6ef2;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    border-radius: 16px;
    line-height: 1.43;
    font-size: 16px;
    min-width: 32px;

    &.dots:hover {
      background-color: transparent;
      cursor: default;
    }
    &:hover {
      background-color: rgba(197, 215, 251, 0.32);
      cursor: pointer;
    }

    &.selected {
      background-color: #2c6ef2;
      color: #fff;
    }
  }
}
